<template>
  <div class="pa-4">
    <!-- FILTRO DE DATAS -->
    <v-form ref="formPesquisa">
      <h3>Pesquisa de Agendas</h3>
      <v-divider class="my-2"></v-divider>
      <v-row class="px-3" dense>
        <v-col>
          <v-text-field v-model="data_inicial" label="Data inicial" :disabled="loading_agendamentos" type="date">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="data_final" label="Data final" :disabled="loading_agendamentos" type="date">
          </v-text-field>
        </v-col>
        <v-col class="pl-10" v-show="false">
          <v-switch v-model="tipo_agendamento_bool" @click="getMedicos('')"
            :label="`${tipo_agendamento_bool ? 'Consultas' : 'Exames'}`"></v-switch>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col sm="6" md="6" dense>
          <!-- SELETOR DA EMPRESA PARA FILTRO DE AGENDAS -->
          <v-autocomplete required :rules="[ruleNotEmpty]" outlined dense hide-details :loading="loading_empresas"
            :disabled="loading_empresas || loading_agendamentos" v-model="pesquisa_empresa"
            prepend-inner-icon="mdi-hospital-box" @change="filtraMedicos()" placeholder="Selecione a empresa..."
            label="Empresa" :items="empresas" item-value="cd_multi_empresa" item-text="ds_multi_empresa"
            :no-data-text="loading_empresas ? 'Carregando Empresa...' : 'Sem empresa'"
            no-results-text="Nenhum registro encontrado" clearable>
          </v-autocomplete>
        </v-col>
        <v-col sm="6" md="6" dense>
          <v-autocomplete outlined dense hide-details :loading="loading_medicos"
            :disabled="!pesquisa_empresa || loading_agendamentos || loading_medicos" v-model="pesquisa_unidade"
            prepend-inner-icon="mdi-hospital-box" placeholder="Selecione uma unidade..." label="Unidade"
            :items="unidade_atendim" item-value="cd_unidade_atendimento" item-text="ds_unidade_atendimento"
            :no-data-text="loading_medicos ? 'Carregando Unidades...' : 'Sem Unidades'"
            no-results-text="Nenhum registro encontrado" clearable />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col sm="5" md="4" dense>
          <!-- SELETOR DE MÉDICO PARA FILTRO DE AGENDAS -->
          <v-autocomplete outlined dense hide-details :loading="loading_medicos" clearable
            :disabled="!pesquisa_empresa || loading_agendamentos || loading_medicos" v-model="pesquisa_medico"
            prepend-inner-icon="mdi-hospital-box" placeholder="Selecione um(a) médico(a)" label="Médico(a)"
            :items="medicos" item-value="cd_prestador" item-text="nm_prestador"
            :no-data-text="loading_medicos ? 'Carregando Médicos...' : 'Sem Médicos'"
            no-results-text="Nenhum registro encontrado" />
        </v-col>
        <!-- Item do agendamento -->
        <v-col sm="5" md="5" dense>
          <v-autocomplete required :rules="[ruleNotEmpty]" dense item-value="cd_item_agendamento"
            item-text="ds_item_agendamento" outlined v-model="cd_item_agendamento" hide-details
            :loading="loading_medicos" :disabled="!pesquisa_empresa || loading_agendamentos" :items="item_agendamentos"
            clearable label="Item de agendamento" placeholder="Selecione um item de agendamento"
            append-outer-icon="mdi-refresh" @click:append-outer=filtraMedicos />
        </v-col>
        <v-col sm="2" md="3" dense>
          <v-btn elevation="0" :disabled="!pesquisa_empresa || !paciente.nr_celular || !paciente.nr_ddd_celular"
            :loading="loading_agendamentos || loading_medicos" block color="primary" @click="carregarAgendasMes()">
            Pesquisar
            <v-icon small right>mdi-calendar-search</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-form ref="formAgendamento">
      <v-row>
        <v-col>
          <h3 class="mt-8">Informações para Agendamento</h3>
        </v-col>
      </v-row>
      <v-row v-show="alert_telefone">
        <v-col class="text-right mt-1" v-if="paciente.nr_ddd_celular === null || paciente.nr_celular === null" :cols="12">
          <v-btn block color="red" @click="volta_paciente()" class="white--text font-weight-bold">PENDENTE O CADASTRO DO
            {{ show_falta_nr_ddd_celular }}
            {{ show_falta_nr_celular }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <v-row dense>
      </v-row>
      <v-row dense align="center">
        <!-- Plano de saúde -->
        <v-col sm="6" md="4" dense>
          <v-autocomplete required :rules="[ruleNotEmpty]" dense item-value="cd_convenio" item-text="nm_convenio"
            v-model="cd_convenio" :disabled="!pesquisa_empresa || loading_agendamentos" outlined :items="convenios"
            hide-details label="Convênio" @change="getSubPlanos('get_subplanos', cd_convenio)" clearable
            placeholder="Selecione um convênio">
          </v-autocomplete>
        </v-col>
        <v-col sm="6" md="4" dense>
          <v-autocomplete required :loading="loading_planos"
            :no-data-text="loading_planos ? 'Carregando planos...' : 'Sem planos'" placeholder="Selecione um plano"
            :disabled="!cd_convenio || loading_agendamentos || loading_planos" :rules="[ruleNotEmpty]" dense
            hide-details item-value="cd_con_pla" item-text="ds_con_pla" v-model="cd_plano" outlined :items="planos"
            label="Plano" clearable>
          </v-autocomplete>
        </v-col>
        <!-- Tipo de marcação da consulta -->
        <v-col sm="6" md="4" dense>
          <v-autocomplete required :rules="[ruleNotEmpty]" dense item-value="cd_tip_mar" item-text="ds_tip_mar"
            hide-details v-model="cd_tip_mar" :disabled="!pesquisa_empresa || loading_agendamentos" outlined
            :items="tiposmarcacao" label="Tipo de marcação" clearable placeholder="Selecione um tipo de marcação">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" dense>
          <v-textarea placeholder="Observações" outlined :disabled="!pesquisa_empresa || loading_agendamentos"
            v-model="observacao" hide-details>
          </v-textarea>
        </v-col>
      </v-row>
      <p class="grey--text">* Os itens destes campos são obrigatórios.</p>
    </v-form>
    <v-card outlined v-show="show_table" class="mt-5">
      <v-card-title>
        <br>
        Unidades e horários
        <v-spacer></v-spacer>
        <div v-if="!!agendamentos && agendamentos.length > 0" class="overline"> {{ agendamentos.length }} agenda(s)
        </div>
      </v-card-title>
      <v-card-text>
        <!-- AGENDAMENTOS DO MÉDICO SELECIONADO -->
        <v-data-table :search="search_agenda" :loading="loading_agendamentos" loading-text="Carregando agendas..."
          :headers="dataTable_header" :items="agendamentos" item-key="cd_it_agenda_central" locale="pt-BR"
          no-data-text="Sem Agenda registrada" no-results-text="Nenhum registro encontrado"  :lazy="true" >
          <template v-slot:item.cd_it_agenda_central="props">
            <span class="grey--text">{{ props.item.cd_it_agenda_central }}</span>
          </template>
          <template v-slot:item.hr_agenda="props">
            {{ props.item.hr_agenda | formatCalendar }}
          </template>
          <template v-slot:item.countdown="props" v-if="hasAgendaSelecionada">
            <div v-if="isSelectedAgenda(props.item)">
              <v-row dense>
                <v-col>
                  <v-btn disabled block elevation="0">
                    <v-icon left>mdi-clock</v-icon>
                    {{ labeled_countdown }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:item.opcoes="props">
            <div v-if="!isSelectedAgenda(props.item)">
              <v-row dense>
                <v-btn block small elevation="0" @click="setReserva(props.item)" color="info" class="ml-1"
                  :disabled="loading_item_reservado || hasAgendaSelecionada" :loading="loading_item_reservado">
                  <v-icon left>mdi-calendar-clock</v-icon>
                  SELECIONAR
                </v-btn>
              </v-row>
            </div>
            <div v-else>
              <v-row dense>
                <v-col dense>
                  <v-btn small block elevation="0" @click="deleteReserva(props.item)" color="red" dark class="ml-1">
                    <v-icon left>mdi-cancel</v-icon>
                    {{ label_cancelar }}
                  </v-btn>
                </v-col>
                <v-col dense>
                  <v-btn small block elevation="0" @click="agendarPaciente(props.item)" color="light green" dark
                    class="ml-1">
                    <v-icon left>mdi-calendar-check</v-icon>
                    AGENDAR
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row dense>
              <v-col>
                <v-autocomplete required :rules="[ruleNotEmpty]" v-if="!tipo_agendamento_bool" dense
                  item-value="cd_item_agendamento" item-text="ds_item_agendamento" outlined
                  v-model="props.item.cd_item_agendamento" :items="props.item.item_agendamentos"
                  label="ITEM AGENDAMENTO"></v-autocomplete>
              </v-col>
            </v-row>
          </template>
          <template v-slot:top>
            <v-autocomplete :items="unidades" prepend-inner-icon="mdi-filter" outlined dense label="Filtrar por Unidade"
              v-model="search_agenda" clearable></v-autocomplete>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          <v-row align='center'>
            <p class="mt-5 mx-auto mb-5">INFORMAÇÕES DO AGENDAMENTO </p>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row dense v-for="(linha, i) in resumo" :key="i">
            <p class="ml-4 mr-auto mb-1">{{ linha }}</p>
          </v-row>
          <v-row class="mb-2">
            <v-divider />
          </v-row>
          <v-row align="center">
            <v-btn dark width=230 class="mx-auto my-3 mb-3" color="primary"
              @click="copyValue(resumo.join('\n'), 'Dados do agendamento')">
              <v-icon left>mdi-content-copy</v-icon>
              COPIAR
            </v-btn>
            <v-btn dark width=230 class="mx-auto my-3 mb-3" color="secondary" @click="dialog = false">
              <v-icon left>mdi-close</v-icon>
              FECHAR
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Aviso medico -->
    <v-dialog v-model="dialoger" width="500">
      <v-card>
        <v-card-title>
          <v-row align='center'>
            <p class="mt-5 mx-auto mb-5">Mensagem </p>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <p class="ml-4 mr-auto mb-1">{{ mensagem }}</p>
          </v-row>
          <v-row class="mb-2">
            <v-divider />
          </v-row>
          <v-row align="center">
            <v-btn dark width=230 class="mx-auto my-3 mb-3" color="secondary" @click="dialoger = false">
              <v-icon left>mdi-close</v-icon>
              FECHAR
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="4000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import AppEtlService from '@/service/AppEtlService'
import AppConfigService from '@/service/AppConfigService'
import UtilsService from '@/service/utilsService';
const api = new AppEtlService();
export default {
  name: 'Agendar',
  props: {
    agendaAtual: {
      type: Object
    },
    paciente: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      countdown_reserva: 600,
      timeout: null,
      interval: null,
      labeled_countdown: '10m 00s',
      resumo: [],
      mensagem: [],
      dialog: false,
      dialoger: false,
      reserva_paciente: false,
      data_inicial: null,
      data_final: null,
      pesquisa_medico: null,
      pesquisa_unidade: null,
      pesquisa_empresa: null,
      pesquisa_mes: null,
      mdi_close: '',
      label_cancelar: '',
      tipo_agendamento_bool: true,
      agendamentos: [],
      tmp_agendamentos: [],
      monitor_agendamentos: [],
      medicos: [],
      empresas: [],
      planos: [],
      tipos_marcacao: [],
      selected_item_agendamento: [],
      reservaConfirmada: false,
      hasAgendaSelecionada: false,
      search_agenda: '',
      loading_agendamentos: false,
      loading_medicos: false,
      loading_planos: false,
      loading_item_agendamentos: false,
      loading_item_reservado: false,
      loading_empresas: false,
      show_table: false,
      agenda_selecionada: {},
      unidade_atendim: [],
      unidades: [],
      reservado: '',
      convenios: [],
      item_agendamentos: [],
      cd_item_agendamento: '',
      cd_convenio: '',
      cd_plano: '',
      cd_tip_mar: '',
      observacao: '',
      monta_itens: [],
      snack: false,
      snackColor: '',
      snackText: '',
      reservaAgenda: null,
      checkReserva: {},
      dataTable_header: [
        { text: 'Cód. AC', class: 'notranslate', align: 'start', sortable: true, value: 'cd_it_agenda_central' },
        { text: 'Médico', class: 'notranslate', align: 'start', sortable: true, value: 'nm_prestador' },
        { text: 'Unidade', class: 'notranslate', align: 'start', sortable: true, value: 'ds_unidade_atendimento' },
        { text: 'Horário', class: 'notranslate', value: 'hr_agenda', sortable: true },
        { text: '', class: 'notranslate', align: 'start', value: 'opcoes' },
        { text: '', class: 'notranslate', align: 'start', value: 'countdown' },
      ],
    }
  },
  methods: {
    handleUnload(event) {
      if (this.hasAgendaSelecionada) {
        event.returnValue = 1
        return 1
      }
    },
    setReserva(agenda) {
      this.reservaAgenda = agenda
      this.loading_item_reservado = true
      this.isReservado(agenda)
    },
    async deleteReserva() {
      this.label_cancelar = 'Cancelando...'
      this.loading_item_reservado = true
      this.loading_item_reservado = false
      this.hasAgendaSelecionada = false
      this.reserva_paciente = false
      this.agenda_selecionada = {}
      this.countdown_reserva = 600
      let conf = [null, null, this.reservaAgenda.cd_it_agenda_central]
      await api.put_etl('etl/query-put/?cod_query=put_agenda_reserva', conf)

    },
    copyValue(value, label) {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = `${label} copiado para a área de transferência.`
      try {
        navigator.clipboard.writeText(value)
      }
      catch {
        this.unsecuredCopyToClipboard(value)
      }
    },
    unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    },
    ruleNotEmpty: (value) => !!value,
    async getConvenios(url, obj) {
      this.loading = true
      return await api.get_query(url, obj)
        .then(response => {
          this.convenios = response.data
        }).catch(error => {
          console.log(error.response)
        }).finally(() => {
          this.loading = false
        })
    },
    async getUnidade() {
      this.loading = true
      const response = await api
        .get_query('get_unidade_atendime', [this.pesquisa_empresa])
        .then(res => res)
        .catch(err => {
          console.log(err)
          return err.response
        })
      this.unidade_atendim = response.data
      this.loading = false

    },
    filtraMedicos() {
      if (this.pesquisa_empresa === null) {
        return
      }
      if (this.pesquisa_empresa == 2) {
        this.getConvenios('get_convenios_vm', [])
      } else {
        this.getConvenios('get_convenios', ['', '%'])
      }
      this.getUnidade()
      this.getMedicos('get_medicos', [this.data_inicial, this.data_final, this.data_inicial, this.data_final, '%', '%', '%', '%', this.data_inicial, this.data_final, this.pesquisa_empresa]);
    },
    limparVars() {
      this.$emit('clearAgenda')
      // this.agendaAtual = null
    },
    async carregarAgendasMes(redirect = false, acao_agendar = true) {
      // carrega o prazo de trinta dias, se houver, para informar ao usuario.
      if (acao_agendar) {
        this.prazo_trinta_dias()
      }

      this.item_agendamentos.filter((item) => item.cd_item_agendamento === this.cd_item_agendamento).ds_item_agendamento
      if ((this.$refs.formPesquisa.validate() || redirect) && !this.hasAgendaSelecionada) {
        this.getAgendaLivreMedico(
          this.data_inicial,
          this.data_final,
          this.pesquisa_empresa,
          this.pesquisa_medico,
          true,
          this.pesquisa_unidade,
          this.cd_item_agendamento
        )
        this.show_table = true
      } else if (this.hasAgendaSelecionada) {
        let cancel = confirm('Há uma reserva pendente, deseja continuar?')
        if (cancel) {
          this.hasAgendaSelecionada = false
          await this.deleteReserva(this.agenda_selecionada.agenda)
          this.carregarAgendasMes()
        }
      } else alert('Campo obrigatório para pesquisar agendas não foi preenchido!')
    },
    isReservado(agenda) { // VERIFICA SE A AGENDA ESTA RESERVADA PARA OUTRO PACIENTE
      api
        .get_query('get_agenda_check', [agenda.cd_it_agenda_central])
        .then((res) => {
          this.checkReserva = res.data

          let { cd_usuario, nm_paciente } = this.checkReserva[0]
          if (cd_usuario === null && nm_paciente === null) {
            this.label_cancelar = 'Aguardando...'
            this.agenda_selecionada.agenda = agenda
            let conf = [this.paciente.nm_paciente, this.usuario, agenda.cd_it_agenda_central]
            api.put_etl('etl/query-put/?cod_query=put_agenda_reserva', conf)
              .then(() => {
                this.label_cancelar = 'Cancelar'
                this.loading_item_reservado = false
                this.hasAgendaSelecionada = true
                window.addEventListener('beforeunload', this.handleUnload)
                window.onunload = () => this.deleteReserva(this.reservaAgenda)
              })
              .catch(() => { alert('Houve um erro ao reservar a agenda.') })
          } else {
            alert(`O horario já está reservado pelo usuário: ${cd_usuario}!`)
            this.carregarAgendasMes()
            this.loading_item_reservado = false
          }
        })

    },
    cancelarPacienteAgenda() {
      this.label_cancelar = 'Cancelando...'
      this.loading_item_reservado = true
      let conf = [null, null, this.agenda_selecionada.agenda.cd_it_agenda_central]
      api
        .put('etl/query-put/?cod_query=put_agenda_reserva', conf)
        .then((res) => {
          this.agenda_selecionada.agenda.cd_it_agenda_central = ''
          this.label_cancelar = ''
          this.mdi_close = 'mdi-close'
          console.log(res)
        })
        .catch((error) => error.response)


      this.loading_item_reservado = false
      this.reserva_paciente = false
    },
    reservarPacienteAgenda() {
      let conf = [
        this.paciente.nm_paciente,
        this.usuario,
        this.agenda_selecionada.agenda.cd_it_agenda_central
      ]


      this.loading_item_reservado = true
      api
        .put_etl('etl/query-put/?cod_query=put_agenda_reserva', conf)
        .then(() => {
          this.label_cancelar = 'Cancelar'
          this.mdi_close = 'mdi-close'
        }).catch(error => {
          console.log(error)
          alert("Houve um erro na hora de agendar")
        }).finally(() => { this.loading_item_reservado = false })


    },
    desabilitar() {
      if (!this.nr_celular) {
        return this.disabled = (this.disabled + 1) % 2
      }
    },
    selctTipMarcConvenio() {
      console.log('selctTipMarcConvenio', this.agenda_selecionada.agenda.cd_tip_mar)
      return this.agenda_selecionada.agenda.cd_tip_mar && this.agenda_selecionada.agenda.cd_convenio
    },
    checkRemacarcao() {

      if (this.agendaAtual !== null) {
        api.put_etl('etl/query-put/?cod_query=del_agenda_paciente', JSON.stringify([this.agendaAtual.cd_it_agenda_central]))

      }

    },
    agendarPaciente(agenda) {
      if (!this.$refs.formAgendamento.validate()) {
        alert('Campo obrigatório para finalizar o agendamento não foi preenchido!')
        return
      }
      this.label_cancelar = 'Aguardando...'
      this.agenda_selecionada ??= {}
      const { cd_convenio, cd_item_agendamento, cd_paciente, nm_paciente, dt_nascimento, tp_sexo, nr_ddd_celular, nr_celular, nr_ddd_fone, nr_fone } = this.paciente
      let mountedAgenda = { ...agenda, cd_convenio, cd_item_agendamento, cd_paciente, nm_paciente, dt_nascimento, tp_sexo, nr_ddd_celular, nr_celular, nr_ddd_fone, nr_fone }
      this.agenda_selecionada.agenda = mountedAgenda
      this.reserva_paciente = true
      let confirmaAgendamento = confirm("Deseja confirmar este agendamento?")
      if (confirmaAgendamento) {
        this.checkRemacarcao()
        this.$emit('clearAgenda')
        let obj = [
          mountedAgenda.cd_paciente,
          mountedAgenda.nm_paciente,
          mountedAgenda.dt_nascimento?.replace('T', ' '),
          this.cd_item_agendamento,
          this.usuario,
          this.cd_convenio,
          this.cd_tip_mar,
          mountedAgenda.tp_sexo,
          mountedAgenda.nr_ddd_celular,
          mountedAgenda.nr_celular,
          mountedAgenda.nr_ddd_fone,
          mountedAgenda.nr_fone,
          this.cd_plano,
          this.observacao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
          mountedAgenda.cd_it_agenda_central,
        ]
        api.put('etl/query-put/?cod_query=put_agenda_paciente', JSON.stringify(obj))
          .then((e) => {
            if(e.data.status==='ok'){
              this.resumo = []
              let agenda = this.agenda_selecionada.agenda
              let filteredItems = this.item_agendamentos.filter(item => item.cd_item_agendamento === this.cd_item_agendamento)
              let itemAgenda = filteredItems.shift().ds_item_agendamento
              let filteredConvenios = this.convenios.filter(item => item.cd_convenio === this.cd_convenio)
              let convenioAgenda = filteredConvenios.shift().nm_convenio
              var marcacao = this.tiposmarcacao.filter(e => { return e.cd_tip_mar == this.cd_tip_mar })
              this.resumo.push(`Nome do Paciente: ${agenda.nm_paciente}`)
              this.resumo.push(`Médico: ${agenda.nm_prestador}`)
              this.resumo.push(`Convênio: ${convenioAgenda}`)
              this.resumo.push(`Agendamento: ${itemAgenda} (${marcacao[0].ds_tip_mar})`)
              this.resumo.push(`Data: ${UtilsService.formatCalendar(agenda.hr_agenda)}`)
              this.resumo.push(`Local: ${agenda.ds_unidade_atendimento}`)
              this.dialog = true
              this.hasAgendaSelecionada = false
              this.$emit('reloadAgendamentos')
              this.carregarAgendasMes(false, false)
            }else if (e.data.status !== 'ok') {
              alert("Erro no agendamento")
              // this.pararAgenda(obj[14])
            }
          })
          .catch(()=>{
              alert("Erro no agendamento")
              this.pararAgenda(obj[14])

          } )
      }
    },
    pararAgenda(value) {
        api.put_etl('etl/query-put/?cod_query=del_agenda_paciente', JSON.stringify([value]))
},
    ativaItensAgendados() {
      return false
    },
    validateSelectedAgenda() {
      return this.agenda_selecionada && this.agenda_selecionada.agenda
    },
    isReservationAgenda(agenda) {
      try {
        return agenda.reservado != null
      } catch {
        return false
      }
    },
    habilita(props) {
      return !props.cd_item_agendamento
    },
    isSelectedAgenda(agenda) {
      try {
        return this.agenda_selecionada.agenda.cd_it_agenda_central == agenda.cd_it_agenda_central
      }
      catch { return false }
    },
    selecionarAgenda(agenda) {
      this.label_cancelar = 'Aguardando...'
      this.agenda_selecionada = {};
      const { cd_convenio, cd_item_agendamento, cd_paciente, nm_paciente, dt_nascimento, tp_sexo, nr_ddd_celular, nr_celular } = this.paciente
      agenda = { ...agenda, cd_convenio, cd_item_agendamento, cd_paciente, nm_paciente, dt_nascimento, tp_sexo, nr_ddd_celular, nr_celular }
      this.agenda_selecionada = { ...{ agenda } }
      this.reserva_paciente = true
      this.agendarPaciente()
    },
    montaAgenda(agenda) {
      this.agenda_selecionada = {};
      const { cd_convenio, cd_item_agendamento, cd_paciente, nm_paciente, dt_nascimento, tp_sexo, nr_ddd_celular, nr_celular } = this.paciente
      agenda = { ...agenda, cd_convenio, cd_item_agendamento, cd_paciente, nm_paciente, dt_nascimento, tp_sexo, nr_ddd_celular, nr_celular }
      this.agenda_selecionada = { ...{ agenda } }
      this.monta_itens = this.selected_item_agendamento.map(e => {
        if (e.cd_item_agendamento != undefined) {
          return e
        }
      })
    },
    monitorAgendamentos(inicial, final, empresa = 1, medico) {
      return api
        .get_query('get_agenda_central', [empresa, inicial, final, medico, "A"])
        .then((response) => {
          this.monitor_agendamentos = response.data;
          this.agendamentos.map(e => {
            this.monitor_agendamentos.map(a => {

              if (e.cd_it_agenda_central == a.cd_it_agenda_central && e.reservado != a.reservado) {
                this.agendamentos[this.agendamentos.indexOf(e)].reservado = a.reservado
              }
            })
          })
        })
        .catch(error => { console.log(error.response) })
    },
    getItemAgendamento() {
      this.loading_item_agendamentos = true
      return api
        .get_etl('etl/query', `cod_query=get_item_agendamento&params=["${this.tipo_agendamento}"]`)
        .then((response) => { this.item_agendamentos = response.data; })
        .catch(error => { console.log(error) })
        .finally(() => { this.loading_item_agendamentos = false })
    },
    async getAgendaLivreMedico(inicial, final, empresa, medico, monitor = false, unidade, itemAgendamento) {
      if (!this.tipo_agendamento_bool) {
        medico = 1
      }
      if (monitor) {
        this.loading_agendamentos = true
      }
      let url = 'get_agenda_central'

      let params = [medico || '%', inicial, final, empresa, "A", unidade || '%', itemAgendamento]
      if (!this.tipo_agendamento_bool) {
        url = 'get_agenda_exames'
        params = [empresa, inicial, final]
      }

      return api
        .get_query(url, params)
        .then((response) => {
          this.agendamentos = response.data;
          console.log(response.data)
          this.unidades = [... new Set(this.agendamentos.map((agenda) => agenda.ds_unidade_atendimento))]
          this.tmp_agendamentos = response.data; //verificar a necessidade de manter esta variavel 
        })
        .catch(error => { console.log(error.response) })
        .finally(() => { this.loading_agendamentos = false })
    },
    async getMedicos(url, params) {

      this.getItemAgendamento()
      this.loading_medicos = true
      return api
        .get_query(url, params)
        .then((response) => { this.medicos = response.data; })
        .catch(error => { console.log(error) })
        .finally(() => { this.loading_medicos = false })
    },
    async getSubPlanos(url, obj) {
      this.loading = true
      this.loading_planos = true
      const response = await api.get_query(url, [obj])
        .then(res => res)
        .catch(error => error.response)

      this.planos = response.data
      this.loading = false
      this.loading_planos = false
    },
    async getTiposMarcacao(url) {
      this.loading = true
      return await api.get_query(url)
        .then(response => {
          this.tipos_marcacao = response.data
        }).catch(error => {
          console.log(error.response)
        }).finally(() => {
          this.loading = false
        })
    },
    async getEmpresas(url, empresas) {
      this.loading_empresas = true
      return await api.get_query(url, empresas)
        .then(response => {
          this.empresas = response.data
        }).catch(error => {
          console.log(error.response)
        }).finally(() => {
          this.loading_empresas = false
        })
    },
    volta_paciente() {
      this.$emit('volta_paciente')
    },
    prazo_trinta_dias() {

      let ini = new Date().toLocaleDateString()
      let fim = new Date()
      fim.setMonth(fim.getMonth() + 1)
      let params = [this.paciente.cd_paciente, `${ini}`, `${fim.toLocaleDateString()}`]

      api.get_query(`get_agenda_paciente`, params)
        .then((r) => {
          //console.log(r.data.length)
          if (r.data.length > 0) {

            let msg = r.data
           
            const format = msg.map(function (element) {
              return element.hr_agenda_formatada

            })
            const medico = msg.map(function (element) {
              return element.nm_prestador
            })
           
            this.mensagem = `Foram encontrado agendas :${format} com médico:${medico}`
            this.dialoger = true
          }
        })
    }
  },
  computed: {
    usuario: () => {
      let storage = JSON.parse(localStorage.getItem('cfgu'))
      return storage.usuario_mv.toUpperCase()
    },
    empresasmv: () => {
      let multi_empresas = JSON.parse(localStorage.getItem('cfgu')).multi_empresas
      return [0, 0, 0].map((_, idx) => multi_empresas[idx] ?? "")
    },
    mensagem_telefone_faltou() {
      return this.paciente.nr_celular === "" ? "Falta o telefone do paciente, favor cadastrar!" : ""
    },
    mensagem_ddd_telefone_faltou() {
      return this.paciente.nr_ddd_celular === "" ? "Falta o ddd, favor cadastrar!" : ""
    },
    tiposmarcacao() {
      return this.tipo_agendamento_bool ? this.tipos_marcacao : [{ "cd_tip_mar": 16, "ds_tip_mar": "EXAMES AMBULATORIAL" }]
    },
    tipo_agendamento() {
      return this.tipo_agendamento_bool ? 'A' : 'I'
    },
    meses() {
      return AppConfigService.meses()
    },
    computedIsSelectedAgenda() {
      if (this.agenda_selecionada && this.agenda_selecionada.agenda && this.agenda_selecionada.agenda.cd_it_agenda_central) {
        return this.agenda_selecionada.agenda.cd_it_agenda_central
      } else {
        return 0
      }
    },
    alert_telefone() {
      return this.paciente.nr_celular !== undefined || this.paciente.nr_ddd_celular !== undefined
    },
    show_falta_nr_celular() {
      return this.paciente.nr_celular || 'NÚMERO CELULAR'
    },
    show_falta_nr_ddd_celular() {
      return this.paciente.nr_ddd_celular || ' DDD '
    }
  },
  watch: {
    agendaAtual: {
      deep: true,
      handler(value) {
        if (value !== null) {
          this.getEmpresas('get_empresas', this.empresasmv)
          this.pesquisa_empresa = Number(value.cd_multi_empresa)
          this.pesquisa_unidade = value.cd_unidade_atendimento
          this.filtraMedicos()
          this.pesquisa_medico = value.cd_prestador
          this.cd_tip_mar = value.cd_tip_mar
          this.cd_convenio = value.cd_convenio
          this.getSubPlanos('get_subplanos', this.cd_convenio)
          this.cd_plano = value.cd_con_pla
          this.observacao = value.ds_observacao
          this.cd_item_agendamento = value.cd_item_agendamento
          this.carregarAgendasMes(true)
        }
      },
    }
  },
  mounted() {
    let dt = new Date()
    let dttmp = new Date()
    let dtf = new Date(dttmp.setMonth(dttmp.getMonth() + 1))
    this.data_inicial = dt.toISOString().split('T')[0]
    this.data_final = dtf.toISOString().split('T')[0]
    if (!this.usuario && this.usuario.length < 0) {
      alert("Usuário MV não informado, entre em contato com seu supervisor para atualizar o seu cadastro")
    } else {
      this.getTiposMarcacao('get_tipo_marcacao')
      this.getItemAgendamento()
      this.getEmpresas('get_empresas', this.empresasmv)
      if (this.agendaAtual !== null) {
        this.getEmpresas('get_empresas', this.empresasmv)
        this.pesquisa_empresa = Number(this.agendaAtual.cd_multi_empresa)
        this.pesquisa_unidade = this.agendaAtual.cd_unidade_atendimento
        this.filtraMedicos()
        this.pesquisa_medico = this.agendaAtual.cd_prestador
        this.cd_tip_mar = this.agendaAtual.cd_tip_mar
        this.cd_convenio = this.agendaAtual.cd_convenio
        this.getSubPlanos('get_subplanos', this.cd_convenio)
        this.cd_plano = this.agendaAtual.cd_con_pla
        this.observacao = this.agendaAtual.ds_observacao
        this.cd_item_agendamento = this.agendaAtual.cd_item_agendamento
        this.carregarAgendasMes(true)
      }
    }
  },
  beforeUnmount() {
    console.log('clear...')
    this.$emit('clearAgenda')
    
  }
}
</script>